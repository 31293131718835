/**
 * api接口统一管理
 */
import { post } from './http'
/**
 * 登录获取用户信息
 * @param {Object} p 请求参数
 */
export const getuserinfo = p => post('/api/v2/pc/getuserinfo', p)
/**
 * 退出登录
 * @param {Object} p 请求参数
 */
export const logoutName = p => post('/api/v1/pc/logout', p)
