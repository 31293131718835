import En from './EN.json'

const Zh = {}

const keys = Object.keys(En)

keys.forEach(k => {
  Zh[k] = k
})
export default Zh
