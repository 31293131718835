import { ElMessage, ElMessageBox } from 'element-plus'
const message = {
  error: (text) => {
    ElMessage({
      showClose: true,
      message: text,
      grouping: true, // 是否合并内容相同的消息
      repeatNum: -10000, // 重复次数初始数值，如想重复次数不显示，则该值设置为负无穷
      type: 'error'
    })
  },
  warning: (text) => {
    ElMessage({
      showClose: true,
      message: text,
      grouping: true, // 是否合并内容相同的消息
      repeatNum: -10000, // 重复次数初始数值，如想重复次数不显示，则该值设置为负无穷
      type: 'warning'
    })
  },
  success: (text) => {
    ElMessage({
      showClose: true,
      message: text,
      grouping: true, // 是否合并内容相同的消息
      repeatNum: -10000, // 重复次数初始数值，如想重复次数不显示，则该值设置为负无穷
      type: 'success'
    })
  },
  confirm: (content, title, type) => {
    return new Promise((resolve) => {
      if (!type) {
        type = 'warning'
      }
      if (!title) {
        title = '提示'
      }
      ElMessageBox.confirm(
        content,
        title,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: type
        }
      ).then((state) => {
        if (state === 'confirm') {
          resolve(true)
        }
        resolve(false)
      }).catch(() => { resolve(false) })
    })
  },
  toast: (text) => {
    ElMessage({
      customClass: 'toast-msg', // 自定义样式，详见：base.css
      offset: 300,
      duration: 1000,
      showClose: false,
      message: text
    })
  }
}
export default message
