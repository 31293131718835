import { fabric } from 'fabric-with-erasing'

fabric.BaseThreeBrush = fabric.util.createClass(fabric.PencilBrush, {
  color: '#fff',
  opacity: 1,
  width: 2,
  canvas: null,
  currentWidth: 0.5,
  currentPoint: null,
  lastPoint: null,
  sime: 0,
  group: null,
  temporaryPoints: [],
  addOfInstance: 0,
  currentOpacity: 0.1,

  initialize: function (canvas, options) {
    this.currentPoint = null
    this.lastPoint = null
    this.currentWidth = 0.5
    this.temporaryPoints = []
    this.addOfInstanceWidth = (this.width - this.currentWidth) / 5 // 增加幅度，每次增加多少宽度
    this.addOfInstanceOpacity = (1 - this.currentOpacity) / 20 // 增加幅度，每次增加多少透明度
    this.callSuper('initialize', canvas, options)

    // this.canvas = canvas
  },

  onMouseDown: function (point, options) {
    // this.currentPoint = { x: point.x, y: point.y }
    // this.lastPoint = { x: point.x, y: point.y }

    // this.group = new fabric.Group([])
    // this.canvas.add(this.group)
    // this.addPathSegment([{ x: point.x, y: point.y }])

    // const line = new fabric.Line([100, 200, 300, 400], { stroke: '#fff', selectable: false })
    // this.canvas.add(line)
    // console.log(line._toSVG())
    this.callSuper('onMouseDown', point, options)
  },

  onMouseMove: function (point, options) {
    // if (!this.lastPoint) return

    // this.temporaryPoints.push({ x: point.x, y: point.y })

    // if (this.temporaryPoints.length >= 2) {
    //   this.currentPoint = { x: point.x, y: point.y }
    //   this.currentWidth = Math.min(this.currentWidth + this.addOfInstanceWidth, this.width)
    //   this.currentOpacity = Math.min(this.currentOpacity + this.addOfInstanceOpacity, 1)
    //   this.addPathSegment([
    //     { x: this.lastPoint.x, y: this.lastPoint.y },
    //     ...this.temporaryPoints
    //   ])

    //   this.lastPoint = point
    //   this.temporaryPoints = []
    // }
    this.callSuper('onMouseMove', point, options)
  },

  onMouseUp: function (point, options) {
    this.currentPoint = null
    this.lastPoint = null
    this.currentWidth = 0.5
    this.temporaryPoints = []
    this.currentOpacity = 0.1
    console.log(this.canvas)
    this.callSuper('onMouseUp', point, options)
  },

  addPathSegment: function (points) {
    const pathString = this.convertPointsToSVGPath(points)
    const newPath = new fabric.Path(pathString, {
      stroke: this.color,
      strokeWidth: this.width,
      fill: '',
      selectable: false,
      evented: false,
      opacity: this.currentOpacity,

      strokeLineJoin: 'miter'
    })

    this.group.addWithUpdate(newPath)
    this.canvas.requestRenderAll()
  },

  convertPointsToSVGPath: function (points) {
    if (points.length < 2) return ''

    let path = `M ${points[0].x} ${points[0].y}`

    for (let i = 1; i < points.length; i++) {
      const startPoint = points[i - 1]
      const endPoint = points[i]
      const midPoint = {
        x: (startPoint.x + endPoint.x) / 2,
        y: (startPoint.y + endPoint.y) / 2
      }
      path += ` Q ${midPoint.x} ${midPoint.y} ${endPoint.x} ${endPoint.y}`
    }

    return path
  }
})
