<template>
  <div>
    <el-dialog
      v-model="state.servedialogVisible"
      width="80vw"
      :show-close="false"
      :destroy-on-close="true">
      <div style="padding: 30px 20px; text-align: left; max-height: 50vh;overflow-y: auto;">
        <div style="font-size: 20px; font-weight: 900;">亦心AI创作服务协议</div>
        <div>
          <br/>本服务协议（以下简称“协议”）是您（以下或称“用户”）与北京亦心科技有限公司(以下称“亦心”）之间就亦心向您提供亦心AI创作服务
          (以下称“服务”)所达成的合法协议，本协议内容与亦心的《隐私协议》、《悟空图像用户协议》和《授权协议》及所有亦心已发布或未来可能发布的各类规则、公告或通知（如有）具有同等效力。前述规则、公告、通知一经发布即为本协议不可分割的组成部分，您同样应当遵守，本协议若与《隐私协议》、《悟空图像用户协议》和《授权协议》等规则冲突的，以本协议为准，本协议未提及的部分以《隐私协议》、《悟空图像用户协议》和《授权协议》等规则为准。您的任何使用行为都视为您认可本协议，同意接受本协议各项条款的约束，包括接受我们在任何时候对任一条款所作的任何修改。我们有权对本协议内的条款随时更新，更新后的协议条款一旦公布即代替原来的协议条款，不再另行通知，如果您不接受修改后的条款，请立即停止使用亦心服务，您继续使用亦心提供的服务将被视为您已接受修改后的协议。因此，请您务必仔细阅读本协议。
          请您确认您具备完全民事权利能力和完全民事行为能力，并对您在本协议项下的全部行为独立承担法律责任。如您未满18周岁，请在法定监护人的陪同下阅读和判断是否同意本协议。
          <br/>
          <br/> 1、使用规范
          <br/>（1）您理解并同意，本服务为辅助您创作的工具，您不得利用本AI创作服务制作、复制、发布、传播干扰亦心平台正常运营秩序，违反法律法规的规定以及侵犯其他用户或第三方合法权利或权益的内容，您不得为其他任何非法目的而使用行本服务。如因您利用亦心提供的服务上传、制作或通过其他方式传播侵犯第三方的合法权利或权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权等）而导致亦心或与亦心合作的其他单位面临任何投诉、举报、质询、索赔、诉讼，或者使亦心或者与亦心合作的其他单位因此遭受任何名誉、声誉或者财产上的损失，您应积极地采取一切可能采取的措施，以保证亦心及与亦心合作的其他单位免受上述索赔、诉讼的影响。同时您对亦心及与亦心合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。
          <br/>（2）您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务的行为进行必要审查，并对生成的内容自行加以判断，承担因使用该生成内容而引起的所有风险，包括因该生成内容的合法性、正确性、完整性而产生的风险。亦心无法且不会对您或您的下游客户因前述风险而导致的任何损失或损害承担责任。
          <br/> 2、用户注意事项
          <br/>（1）您已知悉，使用本服务请确保自定义输入文本未侵害他人合法权利或权益，同时请勿输入任何血腥、暴力、色情、政治相关及违反法律法规或违反公序良俗的内容。
          <br/>（2）您在使用亦心服务时，发现任何内容涉嫌侮辱或者诽谤他人、侵害他人合法权利或权益或违反亦心相关协议的，您可以联系我们。
          <br/>（3）如果亦心主动发现或收到他人举报或投诉您发布的内容违反有关法律法规、公序良俗或本协议约定的，亦心有权不经通知随时对相关内容进行删除，并视行为情况限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，违规账号内如有未使用/消耗完毕的费用，将直接作为违约金归亦心所有，前述违约金不足弥补亦心损失的，亦心有权要求您继续承担赔偿责任；情节严重者，还将进一步追究法律责任。因此违规行为造成的损失等后果（包括但不限于数据及相关信息被删除等），由您自行承担，亦心不因此而向您或任何第三方承担任何赔偿责任。
          <br/>（4）您理解并同意，亦心有权对违反有关法律法规、公序良俗或本协议约定的行为进行合理审查和判断，对违法违规的任何用户采取适当的法律行动，依据法律法规保存相关信息并向有关部门报告、披露任何必要关联信息等，您应独自承担由此而产生的一切法律责任。
          <br/>（5）您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任，如亦心因此遭受损失的，您也应当一并赔偿。
          <br/>（6）您理解并同意，您通过使用服务所生成的内容，将可能被公开展示于亦心平台相关页面，平台用户均可于站内浏览该等内容（包括但不限于提示语、关键词、文本、图像等信息），且任意用户均可使用相同文本内容使用本服务生成图像内容。
          <br/>二、服务费用说明
          <br/>1、亦心AI创作使用规则、收费标准和支付方式以平台提供和相关页面详情公示为准。
          <br/>2、亦心可能根据实际需要对收费服务或功能的范围、收费服务规则或其他规则（如有），收费标准、方式进行修改和变更（但此变化不会影响您既已拥有的权利），如果您不同意上述修改、变更或付费内容，则应停止使用该服务。您享有的使用权限仅限您开通服务之日亦心已存在的服务内容，若后续增加其他服务，并不一定包含在内，您应另行获取授权或另行支付对应费用。
          <br/>3、您理解并同意，基于互联网数字产品的特殊属性，服务开通后，若您中途主动取消服务或因违反使用条款或存在其他不当使用时，您已支付的费用将不支持退款。您亦不得因服务所生成的内容不符合您的要求或期望而主张退款。
          <br/>三、知识产权
          <br/>1、就您使用本服务生成的所有内容，不得侵犯任何人可能拥有的合法权利或权益，如著作权、商标权、隐私权等。除法律法规和本协议另有规定外，如使用（无论是否商用）该生成内容时出现的一切法律风险，将由您自行承担。
          <br/>2、您理解并同意，您使用本服务的，则表明您授予亦心、第三方产品/服务/软件提供方及继任者就所生成内容（包括但不限于提示语、关键词、文本、图像等信息），享有永久的、全球性的、非排他性的、可再许可的、免费的、不可撤销的权利许可，有权以任意方式复制、发行、展示、开发衍生产品、通过互联网传播等，并有权再许可。本许可不因使用本服务的终止而终止。
          <br/>3、您理解并同意，亦心不对使用本服务生成的所有内容是否拥有著作权或知识产权做任何保证。如权利属性及法律性质发生变化，您应当自行承担有关责任，亦心不予负责。
          <br/>4、我们尊重他人的知识产权。如果您认为本服务或与本服务相关的内容侵犯了您的著作权或其他合法权利，请与我们取得联系400-082-0206。
          <br/>四、第三方产品或服务
          <br/>1、本服务可能会使用第三方产品、服务或技术，这种使用已经获得合法授权。
          <br/>2、本产品/服务如果使用了第三方的软件、产品服务或技术，您在使用本产品/服务中由第三方提供的软件、技术或服务时您应当遵守这些协议与要求。如果您没有遵守，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求亦心给予协助，您应当自行承担法律责任。如因本产品/服务使用的第三方软件、产品服务或技术引发的任何纠纷，应由该第三方负责解决，亦心不承担任何责任。
          <br/>3、因您使用本产品/服务时，本产品/服务可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供，亦心不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与亦心无关，亦心不承担任何责任。
          <br/>五、服务提供与责任承担
          <br/>1、对您使用本服务，亦心不作任何明示或暗示的保证，包括但不限于：
          <br/>（1）本服务满足您的使用要求；
          <br/>（2）本服务不受干扰，及时、安全、可靠、准确或不出现错误；
          <br/>（3）本服务的持续提供；
          <br/>（4）您经由本服务取得的任何内容符合您的期望。
          <br/>2、您确认并知悉您使用本服务所生成的内容，亦心不对其准确性、完整性和功能性做任何保证，并且其生成的内容不代表亦心的态度或观点。您应在现行法律允许的范围内使用所生成的内容，该等内容中如涉及第三方内容的，所涉内容所有权仍归原始权利人所有。
          <br/>3、亦心提供的服务在法律法规允许的范围内，包括但不限于已经通过不断的自动及人工敏感数据过滤，但仍不排除其中部分内容具有瑕疵、不合理或引发不快。遇有此情形的，欢迎并感谢您随时向通过本平台所载方式举报。
          <br/>4、您理解并同意，即使亦心服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为亦心违约，亦心也无需承担任何责任。
          <br/>5、您个人操作导致出错的任何结果，由您自行负责，我们不给予任何形式的赔偿。
          <br/>6、鉴于网络服务的特殊性，您同意亦心根据商业判断随时随时变更、中断或终止本服务。如变更、中断或终止的服务属于免费服务，亦心无需通知用户，也无需对用户或任何第三方承担任何责任。
          <br/>六、其他
          <br/>1、本协议适用中华人民共和国法律。
          <br/>2、如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为无效，但本协议的其余条款仍应有效并且有约束力。
          <br/>3、本协议自发布之日起生效。亦心不行使、未能及时行使或者未充分行使本服务条款或者按照法律规定所享有的权利的，不应被视为放弃该权利，也不影响亦心在将来行使该权利。
        </div>
      </div>
      <span>
        <el-button type="primary" @click="state.servedialogVisible = false" style="text-align: center;margin: 20px 0;">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, defineExpose } from 'vue'
const state = reactive({
  servedialogVisible: false
})
const showAIserviceagreement = () => {
  state.servedialogVisible = true
}
defineExpose({ showAIserviceagreement })
</script>
<style scoped lang="scss">
</style>
