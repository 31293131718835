import { fabric } from 'fabric-with-erasing'

fabric.BaseLineFiveBrush = fabric.util.createClass(fabric.BaseBrush, {
  color: '#fff',
  opacity: 1,
  width: 2,
  canvas: null,
  currentWidth: 0.5,
  currentPoint: null,
  lastPoint: null,
  sime: 0,
  group: null,
  temporaryPoints: [],
  addOfInstance: 0,

  initialize: function (canvas, options) {
    this.currentPoint = null
    this.lastPoint = null
    this.currentWidth = 0.5
    this.temporaryPoints = []
    this.addOfInstance = (this.width - this.currentWidth) / 3
    this.canvas = canvas
    this.callSuper('initialize', canvas, options)
  },

  onMouseDown: function (point) {
    this.currentPoint = { x: point.x, y: point.y }
    this.lastPoint = { x: point.x, y: point.y }

    this.group = new fabric.Group([])
    this.canvas.add(this.group)
    this.addPathSegment([])
  },

  onMouseMove: function (point) {
    if (!this.lastPoint) return

    this.temporaryPoints.push({ x: point.x, y: point.y })

    if (this.temporaryPoints.length >= 2) {
      this.currentPoint = { x: point.x, y: point.y }
      this.currentWidth = Math.min(this.currentWidth + this.addOfInstance, this.width)

      this.addPathSegment([
        { x: this.lastPoint.x, y: this.lastPoint.y },
        ...this.temporaryPoints
      ])

      this.lastPoint = point
      this.temporaryPoints = []
    }
  },

  onMouseUp: function () {
    this.currentPoint = null
    this.lastPoint = null
    this.currentWidth = 0.5
    this.temporaryPoints = []
  },

  addPathSegment: function (points) {
    const pathString = this.convertPointsToSVGPath(points)
    const newPath = new fabric.Path(pathString, {
      stroke: this.color,
      strokeWidth: this.currentWidth,
      fill: this.color,
      selectable: false,
      evented: false,
      opacity: this.opacity,
      strokeLineCap: 'round' // 确保端点和连接处是圆角,
    })
    console.log(newPath)
    this.group.addWithUpdate(newPath)
    this.canvas.requestRenderAll()
  },

  convertPointsToSVGPath: function (points) {
    if (points.length < 2) return ''

    let path = `M ${points[0].x} ${points[0].y}`

    for (let i = 1; i < points.length; i++) {
      const startPoint = points[i - 1]
      const endPoint = points[i]
      const midPoint = {
        x: (startPoint.x + endPoint.x) / 2,
        y: (startPoint.y + endPoint.y) / 2
      }
      path += ` Q ${midPoint.x} ${midPoint.y} ${endPoint.x} ${endPoint.y}`
    }

    return path
  }
})
