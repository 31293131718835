import { h } from 'vue'
import { ElMessageBox, ElSelect, ElOption } from 'element-plus'

// 参数配置
export let styleIndex = 0
export const styleDataList = [
  {
    name: '浅色主题',
    htmlFilter: 'unset',
    unInvertColorFilter: 'unset'
  },
  {
    name: '深色主题',
    htmlFilter: 'invert(95%) hue-rotate(0deg)',
    unInvertColorFilter: 'invert(95%) hue-rotate(0deg)'
  }
]

// 设置 主题 函数
const updateStyle = function (index) {
  if (index !== undefined) styleIndex = index
  document.documentElement.style
    .setProperty('--html-filter', styleDataList[styleIndex].htmlFilter)
  document.documentElement.style
    .setProperty('--unInvertColor-filter', styleDataList[styleIndex].unInvertColorFilter)
  localStorage.setItem('aigcThemeIndex', styleIndex)
}

// 初始化主题
const initStyle = function () {
  styleIndex = 0
  if (localStorage.getItem('aigcThemeIndex') && ['0', '1'].indexOf(localStorage.getItem('aigcThemeIndex')) > -1) {
    styleIndex = Number(localStorage.getItem('aigcThemeIndex'))
  }
  updateStyle(styleIndex)
}

// 打开设置主题弹窗
const toSetStyle = function () {
  ElMessageBox({
    title: '切换主题',
    message: () =>
      h(
        ElSelect,
        {
          modelValue: styleIndex,
          'onUpdate:modelValue': (value) => {
            styleIndex = value
          },
          placeholder: '请选择..',
          style: 'width: 100%; padding: 20px 0px;',
          onChange: (value) => {
            updateStyle()
            ElMessageBox.close()
          }
        },
        () => {
          const optionDom = []
          styleDataList.forEach((row, index) => {
            optionDom.push(h(ElOption, { label: row.name, value: index, disabled: false }))
          })
          return optionDom
        }
      ),
    confirmButtonText: '关闭'
  }).then(() => {}).catch(() => {})
}

// 初始化主题
initStyle(0)

// 全局函数
window.updateStyle = updateStyle
window.toSetStyle = toSetStyle
