import { fabric } from 'fabric-with-erasing'

/**
 * 柔和喷枪
 */
fabric.SpraySoftBrush = fabric.util.createClass(fabric.BaseBrush, {
  /**
   * 使画笔绘制直线的事件修饰符键.
   * If `null` or 'none' or any other string that is not a modifier key the feature is disabled.
   * @type {'altKey' | 'shiftKey' | 'ctrlKey' | 'none' | undefined | null}
   */
  straightLineKey: 'shiftKey',
  // 在上次记录的点和当前指针连成线
  drawStraightLine: false,

  /**
   * 构造函数
   */
  initialize: function (canvas) {
    this.canvas = canvas
    this._points = []
  },

  /**
   * 标记画布是否需要完整重绘
   */
  needsFullRender: function () {
    return this.callSuper('needsFullRender') || this._hasStraightLine
  },

  /**
   * 使用quadraticCurveTo在画布上绘画一条平滑直线
   */
  _render: function (ctx) {
    let i
    let len
    let p1 = this._points[0]
    let p2 = this._points[1]
    ctx = ctx || this.canvas.contextTop
    this._saveAndTransform(ctx)
    ctx.beginPath()
    // 如果我们在路径上只有两个点并且它们是相同的这意味着用户只点击了画布而没有移动鼠标那么我们应该画一个点。在两个相同的点之间不会画出一条路径,这就是为什么我们把它们分开一点
    if (this._points.length === 2 && p1.x === p2.x && p1.y === p2.y) {
      const width = this.width / 1000
      p1 = new fabric.Point(p1.x, p1.y)
      p2 = new fabric.Point(p2.x, p2.y)
      p1.x -= width
      p2.x += width
    }
    ctx.moveTo(p1.x, p1.y)
    for (i = 1, len = this._points.length; i < len; i++) {
      this._drawSegment(ctx, p1, p2)
      p1 = this._points[i]
      p2 = this._points[i + 1]
    }
    ctx.lineTo(p1.x, p1.y)
    ctx.stroke()
    ctx.restore()
  },

  /**
   * 鼠标事件 - 按下
   */
  onMouseDown: function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    this.drawStraightLine = options.e[this.straightLineKey]
    this._prepareForDrawing(pointer)
    // 立即获取坐标(允许绘制点(当运动从未发生时))
    this._captureDrawingPath(pointer)
    // 特效
    const ctx = this.canvas.contextTop
    // ctx.strokeStyle = this.color
    ctx.lineWidth = this.width
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
    ctx.shadowBlur = this.width
    ctx.shadowColor = this.color
    // 加载
    this._render()
  },

  /**
   * 绘画开始
   */
  _prepareForDrawing: function (pointer) {
    const p = new fabric.Point(pointer.x, pointer.y)
    this._reset()
    this._addPoint(p)
    this.canvas.contextTop.moveTo(p.x, p.y)
  },

  /**
   * 鼠标事件 - 移动
   */
  onMouseMove: function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    this.drawStraightLine = options.e[this.straightLineKey]
    if (this.limitedToCanvasSize === true && this._isOutSideCanvas(pointer)) {
      return
    }
    if (this._captureDrawingPath(pointer) && this._points.length > 1) {
      if (this.needsFullRender()) {
        this.canvas.clearContext(this.canvas.contextTop)
        this._render()
      } else {
        const points = this._points
        const length = points.length
        const ctx = this.canvas.contextTop
        this._saveAndTransform(ctx)
        if (this.oldEnd) {
          ctx.beginPath()
          ctx.moveTo(this.oldEnd.x, this.oldEnd.y)
        }
        this.oldEnd = this._drawSegment(ctx, points[length - 2], points[length - 1], true)
        // 绘画
        ctx.stroke()
        ctx.restore()
      }
    }
  },

  /**
   * 鼠标移动绘制
   * @param {Object} pointer
   */
  _drawSegment: function (ctx, p1, p2) {
    const midPoint = p1.midPointFrom(p2)
    ctx.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y)
    return midPoint
  },

  /**
   * 鼠标事件 - 上抬
   */
  onMouseUp: function (options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return true
    }
    this.drawStraightLine = false
    this.oldEnd = undefined
    this._finalizeAndAddPath()
    return false
  },

  /**
   * 画布添加绘画路径
   */
  _finalizeAndAddPath: async function () {
    const ctx = this.canvas.contextTop
    ctx.closePath()
    // 对象创建
    const img = await this.cxtToLayer()
    // 路径添加逻辑
    this.canvas.clearContext(ctx)
    this.canvas.add(img)
    this.canvas.requestRenderAll()
    // 事件创建
    this.canvas.fire('path:created', { path: '' })
  },

  /**
   * 获取绘画点
   */
  _captureDrawingPath: function (pointer) {
    const pointerPoint = new fabric.Point(pointer.x, pointer.y)
    return this._addPoint(pointerPoint)
  },

  /**
   * 添加绘画记录点
   */
  _addPoint: function (point) {
    if (this._points.length > 1 && point.eq(this._points[this._points.length - 1])) {
      return false
    }
    if (this.drawStraightLine && this._points.length > 1) {
      this._hasStraightLine = true
      this._points.pop()
    }
    this._points.push(point)
    return true
  },

  /**
   * 重置
   */
  _reset: function () {
    this._points = []
    this._setBrushStyles(this.canvas.contextTop)
    this._setShadow()
    this._hasStraightLine = false
  },

  /**
   * Uint8ClampedArray 转换为 Base64 编码的字符串
   */
  imageDataToBase64: function (imageData) {
    let binary = ''
    const bytes = new Uint8Array(imageData.data)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return 'data:image/png;base64,' + btoa(binary)
  },

  /**
   * 通过上下文获取截图并转化成图片
   */
  cxtToLayer: async function () {
    return new Promise((resolve) => {
      const ctx = this.canvas.contextTop
      const canvas = ctx.canvas
      const imageBase = canvas.toDataURL('image/png', 1)
      fabric.Image.fromURL(imageBase, (img) => {
        img.scale(1).set({
          top: 0,
          left: 0,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
          crossOrigin: 'anonymous',
          url: imageBase,
          erasable: true
        })
        resolve(img)
      })
    })
  }
})
