import { fabric } from 'fabric-with-erasing'

/**
 * 基本3号
 */

fabric.Base3Brush = fabric.util.createClass(fabric.BaseBrush, {

  /**
   * 构造函数
   */
  initialize: function (canvas) {
    this.canvas = canvas
    this._points = []
    this.particles = []
    this._pointsIndex = 0
    this.penWidth = 0
    this.penColor = 'rgba(0, 0, 0, 0)'
  },

  /**
   * 标记画布是否需要完整重绘
   */
  needsFullRender: function () {
    return this.callSuper('needsFullRender') || this._hasStraightLine
  },

  /**
   * 初始化
   */
  _reset: function () {
    this._points = []
    this.particles = []
    this._pointsIndex = 0
    this.penWidth = this.width / 10
    this.penColor = 'rgba(0, 0, 0, 0)'
    this._setBrushStyles(this.canvas.contextTop)
    this._hasStraightLine = false
  },

  /**
   * 鼠标事件 - 按下
   */
  onMouseDown: function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    // 初始化
    this._reset()
    this._addPoint(pointer)
    let penAlpha = 0
    // 特效
    const ctx = this.canvas.contextTop
    ctx.strokeStyle = 'rgba(0, 0, 0, 0)'
    // 笔锋
    // const intervalIdW = setInterval(() => {
    //   if (this.penWidth >= this.width) {
    //     this.penWidth = this.width
    //     clearInterval(intervalIdW)
    //     return
    //   }
    //   this.penWidth += this.width / 10
    // }, 10)
    // 透明度
    this.penWidth = this.width
    const intervalIdA = setInterval(() => {
      if (penAlpha >= 1) {
        ctx.fillStyle = this.color
        clearInterval(intervalIdA)
        return
      }
      penAlpha += 0.1
      const newColor = this.setTransparency(this.color, penAlpha.toFixed(1)).color
      this.penColor = newColor
    }, 15)
  },

  /**
   * 鼠标事件 - 移动
   */
  onMouseMove: function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    if (this.limitedToCanvasSize === true && this._isOutSideCanvas(pointer)) {
      return
    }
    // 添加绘画点
    this._addPoint(pointer)
    // 绘制
    if (this.needsFullRender()) {
      this.canvas.clearContext(this.canvas.contextTop)
    } else {
      const points = this._points
      const length = points.length
      const ctx = this.canvas.contextTop
      this._saveAndTransform(ctx)
      if (this._pointsIndex < length) {
        const lastPointer = points[this._pointsIndex]
        const pointList = this.getLinePoints(lastPointer, pointer)
        // console.log(lastPointer, pointer, pointList)
        for (let i = 1; i < pointList.length; i++) {
          const particleX = pointList[i].x
          const particleY = pointList[i].y
          const radius = this.penWidth / 2
          ctx.fillStyle = this.penColor
          ctx.beginPath()
          ctx.arc(particleX, particleY, radius, 0, 2 * Math.PI)
          ctx.fill()
        }
        this._pointsIndex += 1
      }
    }
  },

  /**
   * 获取路径点
   * @param {*} lastPointer 点坐标
   * @param {*} pointer 点坐标
   * @returns 坐标集合
   */
  getLinePoints: function (lastPointer, pointer) {
    const points = [{
      x: Math.round(lastPointer.x),
      y: Math.round(lastPointer.y)
    }, {
      x: Math.round(pointer.x),
      y: Math.round(pointer.y)
    }]
    const getCentrePointer = (pointer1, pointer2) => {
      return {
        x: Math.round((pointer1.x + pointer2.x) / 2),
        y: Math.round((pointer1.y + pointer2.y) / 2)
      }
    }
    const updateLinePoints = (list) => {
      if (Math.abs(list[0].x - list[1].x) + Math.abs(list[0].y - list[1].y) <= 2) {
        return list
      }
      const newList = []
      for (let i = 0; i < list.length; i++) {
        newList.push(list[i])
        const nextIndex = i + 1
        if (nextIndex === list.length) {
          const distance = Math.abs(newList[0].x - newList[1].x) + Math.abs(newList[0].y - newList[1].y)
          if (distance <= 2) {
            return newList
          } else {
            return updateLinePoints(newList)
          }
        }
        const centrePointer = getCentrePointer(list[i], list[nextIndex])
        newList.push(centrePointer)
      }
    }
    return updateLinePoints(points)
  },

  /**
   * 鼠标事件 - 上抬
   */
  onMouseUp: function (options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return true
    }
    this._finalizeAndAddPath()
    const ctx = this.canvas.contextTop
    ctx.restore()
    return false
  },

  /**
   * 画布添加绘画路径
   */
  _finalizeAndAddPath: async function () {
    const ctx = this.canvas.contextTop
    ctx.closePath()
    ctx.restore()
    // 对象创建
    const img = await this.cxtToLayer()
    // 路径添加逻辑
    this.canvas.clearContext(ctx)
    this.canvas.add(img)
    this.canvas.requestRenderAll()
    // 事件创建
    this.canvas.fire('path:created', { path: '' })
  },

  /**
   * 通过上下文获取截图并转化成图片
   */
  cxtToLayer: async function () {
    return new Promise((resolve) => {
      const ctx = this.canvas.contextTop
      const canvas = ctx.canvas
      const imageBase = canvas.toDataURL('image/png', 1)
      fabric.Image.fromURL(imageBase, (img) => {
        img.scale(1).set({
          top: 0,
          left: 0,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
          crossOrigin: 'anonymous',
          url: imageBase,
          erasable: true
        })
        resolve(img)
      })
    })
  },

  /**
   * 添加绘画记录点
   */
  _addPoint: function (pointer) {
    const point = new fabric.Point(pointer.x, pointer.y)
    if (this._points.length > 1 && point.eq(this._points[this._points.length - 1])) {
      return false
    }
    this._points.push(point)
    return true
  },

  /**
   * 获取随机数
   * @param {*} min 最小值
   * @param {*} max 最大值
   */
  random: function (min, max) {
    return Math.random() * (max - min) + min
  },

  /**
   * 颜色透明度更改
   * @param {*} colorStr 颜色
   * @param {*} alpha 透明度(满值为1)
   */
  setTransparency: (colorStr, alpha) => {
    const returnData = {
      color: 'rgba(0, 0, 0, 1)',
      data: { r: 0, g: 0, b: 0, a: 1 }
    }
    // 移除字符串中的所有空格
    colorStr = colorStr.replace(/\s/g, '')
    // 处理六位的十六进制颜色
    if (/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.test(colorStr)) {
      const match = colorStr.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
      returnData.color = `rgba(${parseInt(match[1], 16)}, ${parseInt(match[2], 16)}, ${parseInt(match[3], 16)}, ${alpha})`
      returnData.data = {
        r: parseInt(match[1], 16),
        g: parseInt(match[2], 16),
        b: parseInt(match[3], 16),
        a: alpha
      }
    }
    // 处理三位的十六进制颜色
    if (/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i.test(colorStr)) {
      const match = colorStr.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
      returnData.color = `rgba(${parseInt(match[1] + match[1], 16)}, ${parseInt(match[2] + match[2], 16)}, ${parseInt(match[3] + match[3], 16)}, ${alpha})`
      returnData.data = {
        r: parseInt(match[1] + match[1], 16),
        g: parseInt(match[2] + match[2], 16),
        b: parseInt(match[3] + match[3], 16),
        a: alpha
      }
    }
    // 处理rgb格式
    if (/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i.test(colorStr)) {
      const match = colorStr.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i)
      returnData.color = `rgba(${parseInt(match[1])}, ${parseInt(match[2])}, ${parseInt(match[3])}, ${alpha})`
      returnData.data = {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[2]),
        a: alpha
      }
    }
    // 处理rgba格式
    if (/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/i.test(colorStr)) {
      const match = colorStr.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/i)
      returnData.color = `rgba(${parseInt(match[1])}, ${parseInt(match[2])}, ${parseInt(match[3])}, ${alpha})`
      returnData.data = {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[2]),
        a: alpha
      }
    }
    return returnData
  }
})
