import { fabric } from 'fabric-with-erasing'

/**
 * 颗粒感粉笔
 */
fabric.CrayonBrush = fabric.util.createClass(fabric.BaseBrush, {

  /**
   * 构造函数
   */
  initialize: function (canvas, color) {
    this.canvas = canvas
    this._points = []
    this.penWidth = 0
    this.img = new Image()
    const penColor = this.colorStringToRgbaData(color).data
    this.opacityMaskColourChange(require('@/assets/images/flashDrawing/brush_crayon.png'), penColor, true).then((newBase) => {
      this.img.src = newBase
    })
  },

  /**
   * 标记画布是否需要完整重绘
   */
  needsFullRender: function () {
    return this.callSuper('needsFullRender') || this._hasStraightLine
  },

  /**
   * 重置
   */
  _reset: function () {
    this._points = []
    this._setBrushStyles(this.canvas.contextTop)
    this._setShadow()
    this._hasStraightLine = false
  },

  /**
   * 鼠标事件 - 按下
   */
  onMouseDown: async function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    this._reset()
    this._addPoint(pointer)
    // 特效
    const ctx = this.canvas.contextTop
    const pattern = ctx.createPattern(this.img, 'repeat')
    ctx.strokeStyle = pattern
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
    // 加载
    this.penWidth = this.width / 10
    // 笔锋
    const intervalIdW = setInterval(() => {
      if (this.penWidth >= this.width) {
        this.penWidth = this.width
        clearInterval(intervalIdW)
        return
      }
      this.penWidth += this.width / 10
    }, 10)
    // this.canvas.contextTop.moveTo(pointer.x, pointer.y)
  },

  /**
   * 鼠标事件 - 移动
   */
  onMouseMove: function (pointer, options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return
    }
    if (this.limitedToCanvasSize === true && this._isOutSideCanvas(pointer)) {
      return
    }
    // 添加绘画点
    this._addPoint(pointer)
    // 绘制
    if (this._points.length > 1) {
      if (this.needsFullRender()) {
        this.canvas.clearContext(this.canvas.contextTop)
      } else {
        const points = this._points
        const length = points.length
        const ctx = this.canvas.contextTop
        ctx.lineWidth = this.penWidth
        this._saveAndTransform(ctx)
        ctx.beginPath()
        ctx.moveTo(points[length - 2].x, points[length - 2].y)
        ctx.quadraticCurveTo(points[length - 2].x, points[length - 2].y, points[length - 1].x, points[length - 1].y)
        // 绘画
        ctx.stroke()
        ctx.restore()
      }
    }
  },

  /**
   * 鼠标事件 - 上抬
   */
  onMouseUp: function (options) {
    if (!this.canvas._isMainEvent(options.e)) {
      return true
    }
    this.oldEnd = undefined
    this._finalizeAndAddPath()
    return false
  },

  /**
   * 画布添加绘画路径
   */
  _finalizeAndAddPath: async function () {
    const ctx = this.canvas.contextTop
    ctx.closePath()
    // 对象创建
    const img = await this.cxtToLayer()
    // 路径添加逻辑
    this.canvas.clearContext(ctx)
    this.canvas.add(img)
    this.canvas.requestRenderAll()
    // 事件创建
    this.canvas.fire('path:created', { path: '' })
  },

  /**
   * 添加绘画记录点
   */
  _addPoint: function (point) {
    if (this._points.length > 1 && point.eq(this._points[this._points.length - 1])) {
      return false
    }
    this._points.push(point)
    return true
  },

  /**
   * Uint8ClampedArray 转换为 Base64 编码的字符串
   */
  imageDataToBase64: function (imageData) {
    let binary = ''
    const bytes = new Uint8Array(imageData.data)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return 'data:image/png;base64,' + btoa(binary)
  },

  /**
   * 通过上下文获取截图并转化成图片
   */
  cxtToLayer: async function () {
    return new Promise((resolve) => {
      const ctx = this.canvas.contextTop
      const canvas = ctx.canvas
      const imageBase = canvas.toDataURL('image/png', 1)
      fabric.Image.fromURL(imageBase, (img) => {
        img.scale(1).set({
          top: 0,
          left: 0,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
          crossOrigin: 'anonymous',
          url: imageBase,
          erasable: true
        })
        resolve(img)
      })
    })
  },

  /**
   * @desc 颜色转rgba格式
   * @param colorStr 颜色字符串
   */
  colorStringToRgbaData: function (colorStr) {
    const returnData = {
      color: 'rgba(0, 0, 0, 1)',
      data: { r: 0, g: 0, b: 0, a: 1 }
    }
    // 移除字符串中的所有空格
    colorStr = colorStr.replace(/\s/g, '')
    // 处理六位的十六进制颜色
    if (/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.test(colorStr)) {
      const match = colorStr.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
      returnData.color = `rgba(${parseInt(match[1], 16)}, ${parseInt(match[2], 16)}, ${parseInt(match[3], 16)}, 1)`
      returnData.data = {
        r: parseInt(match[1], 16),
        g: parseInt(match[2], 16),
        b: parseInt(match[3], 16),
        a: 1
      }
    }
    // 处理三位的十六进制颜色
    if (/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i.test(colorStr)) {
      const match = colorStr.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
      returnData.color = `rgba(${parseInt(match[1] + match[1], 16)}, ${parseInt(match[2] + match[2], 16)}, ${parseInt(match[3] + match[3], 16)}, 1)`
      returnData.data = {
        r: parseInt(match[1] + match[1], 16),
        g: parseInt(match[2] + match[2], 16),
        b: parseInt(match[3] + match[3], 16),
        a: 1
      }
    }
    // 处理rgb格式
    if (/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i.test(colorStr)) {
      const match = colorStr.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i)
      returnData.color = `rgba(${parseInt(match[1])}, ${parseInt(match[2])}, ${parseInt(match[3])}, 1)`
      returnData.data = {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[2]),
        a: 1
      }
    }
    // 处理rgba格式
    if (/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/i.test(colorStr)) {
      const match = colorStr.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/i)
      returnData.color = `rgba(${parseInt(match[1])}, ${parseInt(match[2])}, ${parseInt(match[3])}, ${parseFloat(match[4])})`
      returnData.data = {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[2]),
        a: parseInt(match[4])
      }
    }
    return returnData
  },

  /**
   * 透明遮罩图颜色更改
   * @param imgBase64    图片base64数据
   * @param rgbaNewData  改变后遮罩颜色 例:{r: 255, g: 0, b: 0, a: 0.5}
   * @param isInversion  是否反转颜色区域
   */
  opacityMaskColourChange: function (imgBase64, rgbaNewData = { r: 255, g: 0, b: 0, a: 0.5 }, isInversion) {
    return new Promise((resolve) => {
      const imageView = new Image()
      imageView.crossOrigin = 'anonymous'
      imageView.src = imgBase64
      imageView.onload = async function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d', { willReadFrequently: true })
        canvas.width = this.width
        canvas.height = this.height
        ctx.drawImage(imageView, 0, 0, canvas.width, canvas.height)
        const newImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const oldImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const data = oldImageData.data
        for (let i = 0, len = data.length; i < len; i += 4) {
          let isOpacity = data[i + 3] < 50 // 是否是透明像素
          if (isInversion) isOpacity = !isOpacity // 是否反转
          if (isOpacity) {
            newImageData.data[i] = 0
            newImageData.data[i + 1] = 0
            newImageData.data[i + 2] = 0
            newImageData.data[i + 3] = 0
          } else {
            newImageData.data[i] = rgbaNewData.r
            newImageData.data[i + 1] = rgbaNewData.g
            newImageData.data[i + 2] = rgbaNewData.b
            newImageData.data[i + 3] = rgbaNewData.a * 255
          }
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.putImageData(newImageData, 0, 0)
        const maskBase = canvas.toDataURL('image/png', 1)
        resolve(maskBase)
      }
    })
  }
})
