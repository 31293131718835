import { createStore } from 'vuex'

export default createStore({
  // store.state.canvasPen
  // store.state.canvasInfo
  // store.state.selectBox
  state: {
    // 通用参数
    data: {}, // 通用对象(用于传参及标记)
    iframeFrom: '', // iframe访问来源
    baseModelList: [], // 基础模型列表
    loraModelList: [], // 风格模型列表
    modelModelList: [], // 模特模型列表
    motioncomicsList: [], // 动态漫风格列表
    loraGoodsModelList: [], // 风格商品列表
    loraFontModelList: [], // 艺术字模型列表
    turboModelList: [],
    fluxModelList: [], // 文生图和图生图基础模型列表
    lorafluxModelList: [], // 文生图和图生图风格模型列表
    flashPaintModelList: [], // 闪绘模型列表
    samplerList: [], // 采样器列表
    adetailerList: [], // 精修列表
    fontList: [], // 字体列表
    fileurl: '', // 文件地址
    imgDetail: null, // 图片对象详情(当前项)
    gcDetail: null, // 生成对象详情(当前项)
    // 画布相关
    aiCanvas: null, // 画布对象
    // 默认配置对象
    defaultSetting: {
      shadeColor: 'rgba(255, 0, 0, 0.5)' // 遮罩颜色
    },
    // 画布信息
    canvasInfo: {
      editMode: 'pen', // 编辑模式(pen画笔编辑, selectBox选区, imgExtend图像扩展, identifyAdd识别ai-添加物体, identifyDel识别ai-删除物体, ban关闭画笔)
      editImgType: 'img', // 当前编辑图层类型(img原图, shade遮罩, depth景深图层, swatches语义图层, stroke轮廓图层)
      zoom: 1, // 画布缩放(视觉缩放)
      width: 900, // 海报宽
      height: 900 // 海报高
    },
    // 画笔属性
    canvasPen: {
      isOpen: true, // 开启绘画模式
      width: 20, // 画笔宽度
      color: 'rgba(0,0,0,1)', // 画笔颜色
      type: 1, // 画笔类型(0 橡皮擦, 1 画笔)
      side: 1, // 画笔边缘(0虚化, 1正常)
      iconCanvas: null, // 画笔图标画布
      iconImage: '' // 画笔图标base64数据
    },
    // 选区属性
    selectBox: {
      type: '框选', // 选区类型 (框选,套索,魔棒)
      shape: 'rectangle', // 框选形状 (rectangle矩形, ellipse圆形, lozenge菱形, star五角形, triangle三角形)
      mode: '新建', // 选区方式(新建, 添加, 减少, 交集, 差集)
      fillColor: 'rgba(0,0,0,1)', // 填充颜色
      tolerance: 30 // 容差
    },
    // 扩展属性
    imgExtend: {
      moveTop: 0, // 扩展距离(上)
      moveDown: 0, // 扩展距离(下)
      moveLeft: 0, // 扩展距离(左)
      moveRight: 0 // 扩展距离(右)
    },

    // 北航
    isFromBHU: false,
    // 用户信息
    userInfo: {
      unread: 0, // 未读消息数量（系统未读消息数量 + 互动未读消息数量）
      sysUnread: 0, // 系统未读消息数量
      msgUnread: 0, // 互动未读消息数量
      remaintimes: 0 // 点数
    },

    currentWorkFlowType: '', // 左侧分类type,
    // 生成参数
    gcParams: {
      width: 1152,
      height: 1152
    },
    workId: 0, // 当前工作流的id
    locationQuery: {}, // 适用于pc端内嵌aigc，存储url上的参数，防止跳转时丢失
    // ai模特识别列表
    modelDetectList: [
      { name: '自定义', ename: 'user', url: '' }, // 用户自定义(用于回显遮罩)
      { name: '全身', ename: 'main', url: '' },
      { name: '帽子', ename: 'hat', url: '' },
      { name: '上衣', ename: 'tops', url: '' },
      { name: '外套', ename: 'coat', url: '' },
      { name: '包类', ename: 'bag', url: '' },
      { name: '裙装', ename: 'skirt', url: '' },
      { name: '裤装', ename: 'pants', url: '' },
      { name: '鞋子', ename: 'shoes', url: '' }
    ]
  },
  // getters: {
  //   isGoods (state) {
  //     return state.currentWorkFlowType === 'goods'
  //   },
  //   isArtFont (state) {
  //     return state.currentWorkFlowType === 'artfont'
  //   }
  // },

  mutations: {
    /**
     * 更新全局配置项参数对象
     */
    setConfigData: (state, payload) => {
      if (payload.baseModelList) state.baseModelList = payload.baseModelList
      if (payload.loraModelList) state.loraModelList = payload.loraModelList
      if (payload.modelModelList) state.modelModelList = payload.modelModelList
      if (payload.motioncomicsList) state.motioncomicsList = payload.motioncomicsList
      if (payload.samplerList) state.samplerList = payload.samplerList
      if (payload.adetailerList) state.adetailerList = payload.adetailerList
      if (payload.fontList) state.fontList = payload.fontList
      if (payload.imgDetail) state.imgDetail = payload.imgDetail
      if (payload.imgDetail === -1) state.imgDetail = null
      if (payload.gcDetail) state.gcDetail = payload.gcDetail
      if (payload.iframeFrom) state.iframeFrom = payload.iframeFrom
      if (payload.locationQuery) state.locationQuery = payload.locationQuery
      if (payload.loraGoodsModelList) state.loraGoodsModelList = payload.loraGoodsModelList
      if (payload.loraFontModelList) state.loraFontModelList = payload.loraFontModelList
      if (payload.flashPaintModelList) state.flashPaintModelList = payload.flashPaintModelList
      if (payload.turboModelList) state.turboModelList = payload.turboModelList
      if (payload.fluxModelList) state.fluxModelList = payload.fluxModelList
      if (payload.lorafluxModelList) state.lorafluxModelList = payload.lorafluxModelList
    },
    fileurl: (state, payload) => {
      state.fileurl = payload
    },
    /**
     * 判断是否是北航页面
     * @param {*} state
     * @param {*} payload
     */
    setIsFromBHU: (state, payload) => {
      state.isFromBHU = payload
    },
    /**
     * 用户信息
     * @param {} state
     * @param {*} payload
     */
    setUserInfo: (state, payload) => {
      if (payload.remaintimes) state.userInfo.remaintimes = payload.remaintimes
      if (payload.sysUnread || payload.sysUnread === 0) state.userInfo.sysUnread = payload.sysUnread
      if (payload.msgUnread || payload.msgUnread === 0) state.userInfo.msgUnread = payload.msgUnread
      if (payload.unread || payload.unread === 0) state.userInfo.unread = payload.unread
    },
    /**
     * 设置左侧tab分类
     * @param {} state
     * @param {*} type
     */
    setworkFlowKind: (state, type) => {
      // state.currentWorkFlowType = type
    },
    /**
     * 设置生成参数尺寸
     * @param {} state
     * @param {*} payload
     */
    setGcParams: (state, payload) => {
      const { width, height } = payload
      state.gcParams.width = width
      state.gcParams.height = height
    },
    /**
     * 设置工作流的workId
     * @param {*} state
     * @param {*} workId
     */
    setWorkId: (state, workId) => {
      state.workId = workId
    }
  },
  actions: {},
  modules: {}
})
